import React from 'react'
import { NavLink } from "react-router-dom";

function Home() {
    return (
        <div className="bg-white">
            <div className="relative isolate px-6 pt-14 lg:px-8">
                <div className="mx-auto max-w-2xl py-32 sm:py-18 lg:py-16">
                <div className="hidden sm:mb-8 sm:flex sm:justify-center">
                    
                </div>
                <div className="text-center">
                    <h5 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                    Show your best data to customers
                    </h5>
                    <p className="mt-6 text-lg leading-8 text-gray-600">
                    How can you improve your customer experience? Using website can help you learn more about your customers and how they experience your brand.
                    </p>
                    <div className="mt-10 flex items-center justify-center gap-x-6">
                    <NavLink
                        to="/icons"
                        className="rounded-md bg-blue-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                    >
                        Go to Icon
                    </NavLink>
                    <NavLink
                        to="/list"
                        className="rounded-md bg-blue-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                    >
                        Go to List
                    </NavLink>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default Home;