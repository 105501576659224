import { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import {
  Navbar,
  Collapse,
  Typography,
  Button,
  IconButton,
} from "@material-tailwind/react";

function StickyNavbar() {
    const [openNav, setOpenNav] = useState(false);
    
    useEffect(() => {
        window.addEventListener(
        "resize",
        () => window.innerWidth >= 960 && setOpenNav(false)
        );
    }, []);

    const [isNavbarTransparent, setIsNavbarTransparent] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            const scrolledToTop = window.pageYOffset === 0;
            setIsNavbarTransparent(scrolledToTop);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const navbarClasses = `sticky inset-0 z-10 h-max max-w-full rounded-xl py-2 px-4 lg:px-8 lg:py-4 ${
        isNavbarTransparent ? 'border-none shadow-none' : 'shadow'
    }`;
    
    const navList = (
        <ul className="mb-4 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
        <Typography
            as="li"
            variant="small"
            color="blue-gray"
            className="p-1 font-normal"
        >
            <NavLink to="/" className="flex items-center">
            Home
            </NavLink>
        </Typography>
        <Typography
            as="li"
            variant="small"
            color="blue-gray"
            className="p-1 font-normal"
        >
            <NavLink to="/icons" className="flex items-center">
            Icon
            </NavLink>
        </Typography>
        <Typography
            as="li"
            variant="small"
            color="blue-gray"
            className="p-1 font-normal"
        >
            <NavLink to="/list" className="flex items-center">
            List
            </NavLink>
        </Typography>
        {/* <Typography
            as="li"
            variant="small"
            color="blue-gray"
            className="p-1 font-normal"
        >
            <NavLink to="/" className="flex items-center">
            Blocks
            </NavLink>
        </Typography>
        <Typography
            as="li"
            variant="small"
            color="blue-gray"
            className="p-1 font-normal"
        >
            <NavLink to="/" className="flex items-center">
            Docs
            </NavLink>
        </Typography> */}
        </ul>
    );

    return (
        <>
        <Navbar className={navbarClasses}>
            <div className="flex items-center justify-between text-blue-gray-900">
            <Typography
                className="mr-4 cursor-pointer py-1.5 font-medium"
            >
                <NavLink to="https://linkedin.com/in/sirojulanam" className="flex items-center">
                Made by Sirojul Anam
                </NavLink>
            </Typography>
            <div className="flex items-center gap-4">
                <div className="mr-4 hidden lg:block">{navList}</div>
                <NavLink to="/login" className="flex items-center">
                <Button
                variant="gradient"
                size="sm"
                className="hidden lg:inline-block"
                >
                    <span>Sign in</span>
                </Button>
                </NavLink>
                <IconButton
                variant="text"
                className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
                ripple={false}
                onClick={() => setOpenNav(!openNav)}
                >
                {openNav ? (
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    className="h-6 w-6"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                    >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                    />
                    </svg>
                ) : (
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4 6h16M4 12h16M4 18h16"
                    />
                    </svg>
                )}
                </IconButton>
            </div>
            </div>
            <Collapse open={openNav}>
            {navList}
            <NavLink to="/login">
            <Button variant="gradient" size="sm" fullWidth className="mb-2">
                <span>Sign in</span>
            </Button>
            </NavLink>
            </Collapse>
        </Navbar>
        </>
    );
}

export default StickyNavbar;