import React, { useEffect, useState, useCallback } from 'react'
import { NavLink } from 'react-router-dom';
import { Button, Input } from "@material-tailwind/react";
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";
import axios from 'axios';

function Icons() {
    const [tableRows, setTableRows] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [search, setSearch] = useState('');

    const fetchData = useCallback(async () => {
        const skip = (currentPage - 1) * itemsPerPage;
        const limit = itemsPerPage;
    
        let endpoint = `https://dummyjson.com/products?limit=${limit}&skip=${skip}`;
        if (search) {
            endpoint = `https://dummyjson.com/products/search?q=${search}`;
        }
    
        try {
            const response = await axios.get(endpoint);
            const data = response.data;
            setTableRows(data.products);
            setTotalPages(Math.ceil(data.total / itemsPerPage));
        } catch (error) {
            console.log(error.response);
        }
    }, [currentPage, itemsPerPage, search]);
    
    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleSearchChange = (event) => {
        setSearch(event.target.value);
        setCurrentPage(1);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleItemsPerPageChange = (event) => {
        const newItemsPerPage = parseInt(event.target.value);
        setItemsPerPage(newItemsPerPage);
        setCurrentPage(1); // Reset current page when changing items per page
    };

    const renderTableRows = () => {
        if (tableRows.length === 0) {
        return (
            <p>Loading...</p>
        );
        }

        return tableRows.map((product) => {

        return (
            <NavLink key={product.id} to={`/detail/${product.id}`} className="group">
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                    <img
                    src={product.thumbnail}
                    alt={product.brand}
                    className="h-64 w-64 object-cover object-center group-hover:opacity-75"
                    />
                </div>
                <h3 className="mt-4 text-sm text-gray-700">{product.title}</h3>
                <p className="mt-1 text-lg font-medium text-gray-900">$ {product.price}</p>
            </NavLink>
        );
        });
    };

    const renderPagination = () => {
        if (search !== '') {
            return <Button
                key='1'
                className='py-2 px-4 rounded focus:outline-none bg-blue-500 text-white'>
                1
            </Button>; // Return null when there is a search term
        }
        
        const pagination = [];
        for (let i = 1; i <= totalPages; i++) {
        pagination.push(
            <Button
            key={i}
            className={`py-2 px-4 rounded focus:outline-none ${
                i === currentPage ? 'bg-blue-500 text-white' : 'bg-white text-blue-500'
            }`}
            onClick={() => handlePageChange(i)}
            >
            {i}
            </Button>
        );
        }
        return pagination;
    };

    const prev = () => {
        if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
        }
    };

    const next = () => {
        if (currentPage < totalPages) {
        setCurrentPage(currentPage + 1);
        }
    };

    return (
        <div className="bg-white">
            <div className="mx-auto max-w-2xl px-4 py-8 sm:px-6 sm:py-12 lg:max-w-7xl lg:px-8">
            <div className="flex items-center mb-1 ml-3">
                <select name="column" id="column" value={itemsPerPage} onChange={handleItemsPerPageChange}>
                    <option value="10">10</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                </select>
                <div className="ml-4 flex">
                    <Input label="Search" value={search} onChange={handleSearchChange} />
                </div>
            </div>

            <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
                {renderTableRows()}
            </div>
                <div className="flex flex-col sm:flex-row justify-center items-center">
                    <Button
                        variant="text"
                        color="blue-gray"
                        className="flex items-center gap-0"
                        onClick={prev}
                        disabled={currentPage === 1 || search !== ''}
                    >
                        <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" /> Previous
                    </Button>
                    <div className="flex items-center gap-0">
                        {renderPagination()}
                    </div>
                    <Button
                    variant="text"
                    color="blue-gray"
                    className="flex items-center gap-0"
                    onClick={next}
                    disabled={currentPage === totalPages || search !== ''}
                    >
                    Next <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default Icons;