import React, { useEffect, useState, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { Card, Typography, Button, Input } from "@material-tailwind/react";
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";
import axios from 'axios';

const TABLE_HEAD = ["Id", "Title", "Description", "Price", ""];

function List() {
  const [tableRows, setTableRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [search, setSearch] = useState('');

  const fetchData = useCallback(async () => {
      const skip = (currentPage - 1) * itemsPerPage;
      const limit = itemsPerPage;

      let endpoint = `https://dummyjson.com/products?limit=${limit}&skip=${skip}`;
      if (search) {
          endpoint = `https://dummyjson.com/products/search?q=${search}`;
      }

      try {
          const response = await axios.get(endpoint);
          const data = response.data;
          setTableRows(data.products);
          setTotalPages(Math.ceil(data.total / itemsPerPage));
      } catch (error) {
          console.log(error.response);
      }
  }, [currentPage, itemsPerPage, search]);

  useEffect(() => {
      fetchData();
  }, [fetchData]);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); // Reset current page when changing items per page
  };

  const renderTableRows = () => {
    if (tableRows.length === 0) {
      return (
        <tr>
          <td colSpan={TABLE_HEAD.length} className="p-4 text-center">Loading...</td>
        </tr>
      );
    }

    return tableRows.map(({ id, title, description, price }, index) => {
      const isLast = index === tableRows.length - 1;
      const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

      return (
        <tr key={id} className="even:bg-blue-gray-50/50">
          <td className={classes}>
            <Typography variant="small" color="blue-gray" className="font-normal">
              {id}
            </Typography>
          </td>
          <td className={classes}>
            <Typography variant="small" color="blue-gray" className="font-normal">
              {title}
            </Typography>
          </td>
          <td className={classes}>
            <Typography variant="small" color="blue-gray" className="font-normal">
              {description}
            </Typography>
          </td>
          <td className={classes}>
            <Typography variant="small" color="blue-gray" className="font-normal">
              {price}
            </Typography>
          </td>
          <td className={classes}>
            <Typography
              variant="small"
              color="blue"
              className="font-medium"
            >
            <NavLink to={`/detail/${id}`}>Detail</NavLink>
            </Typography>
          </td>
        </tr>
      );
    });
  };

  const renderPagination = () => {
    if (search !== '') {
      return <Button
        key='1'
        className='py-2 px-4 rounded focus:outline-none bg-blue-500 text-white'>
        1
      </Button>; // Return null when there is a search term
    }

    const pagination = [];
    for (let i = 1; i <= totalPages; i++) {
      pagination.push(
        <Button
          key={i}
          className={`py-2 px-4 rounded focus:outline-none ${
            i === currentPage ? 'bg-blue-500 text-white' : 'bg-white text-blue-500'
          }`}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Button>
      );
    }
    return pagination;
  };

  const prev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const next = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className="mx-auto max-w-screen-2xl py-12">
      <div className="flex items-center mb-1 ml-3">
        <select name="column" id="column" value={itemsPerPage} onChange={handleItemsPerPageChange}>
          <option value="10">10</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
        <div className="ml-4 flex">
          <Input label="Search" value={search} onChange={handleSearchChange} />
        </div>
      </div>
      <Card className="overflow-scroll h-full w-full">
        <table className="w-full min-w-max table-auto text-left">
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th key={head} className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal leading-none opacity-70"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {renderTableRows()}
          </tbody>
        </table>

      </Card>
      <div className="flex flex-col sm:flex-row justify-center items-center mt-4">
        <Button
            variant="text"
            color="blue-gray"
            className="flex items-center gap-0"
            onClick={prev}
            disabled={currentPage === 1 || search !== ''}
          >
            <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" /> Previous
        </Button>
        {renderPagination()}
        <Button
          variant="text"
          color="blue-gray"
          className="flex items-center gap-0"
          onClick={next}
          disabled={currentPage === totalPages || search !== ''}
        >
          Next <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
        </Button>
      </div>
    </div>
  );
}

export default List;
