import React from 'react';
import {Route, BrowserRouter, Routes} from "react-router-dom";
import StickyNavbar from '../components/StickyNavbar';
import Home from '../pages/Home';
import List from '../pages/List';
import Icons from '../pages/Icons';
import Detail from '../pages/Detail';
import Login from '../pages/auth/Login';

function Index() {
    return (
      <BrowserRouter>
        <StickyNavbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/icons" element={<Icons />} />
          <Route exact path="/list" element={<List />} />
          <Route exact path="/detail/:id" element={<Detail />} />
        </Routes>
      </BrowserRouter>
    );
}

export default Index;