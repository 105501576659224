import React, { useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import {
    Button,
} from "@material-tailwind/react";
import { NavLink } from 'react-router-dom';

function Login() {
    const [email, setEmail] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        
        toast(`Sorry ${email} this page is currently under maintenance.`);
    };

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <Toaster />
            <img
                className="mx-auto h-20 w-20"
                src="https://img.freepik.com/free-vector/shop-cart-shop-building-cartoon_138676-2085.jpg?w=1380&t=st=1685271535~exp=1685272135~hmac=a102d935fb3b2fdd57dc12420d3f89b1a382f6d6d565222e5ff87bb68da70ebd"
                alt="Your Company"
            />
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                Sign in
            </h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form className="space-y-6" onSubmit={handleSubmit}>
                <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                    Email address
                </label>
                <div className="mt-2">
                    <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-blue-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-100 sm:text-sm sm:leading-6"
                    value={email} onChange={(event) => setEmail(event.target.value)}
                    />
                </div>
                </div>

                <div>
                <div className="flex items-center justify-between">
                    <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                    Password
                    </label>
                    <div className="text-sm">
                    <NavLink to="/login" className="font-semibold text-blue-500 hover:text-blue-700">
                        Forgot password?
                    </NavLink>
                    </div>
                </div>
                <div className="mt-2">
                    <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-blue-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-100 sm:text-sm sm:leading-6"
                    />
                </div>
                </div>

                <div>
                <Button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-blue-400 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-400"
                >
                    Sign in
                </Button>
                </div>
            </form>
            </div>
        </div>
    );
}

export default Login;